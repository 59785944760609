import React, { useEffect, useState } from "react"
import { getProfile } from "../../state/action/profile"
import { notifySearch } from "../../state/action/currency"
import { useDispatch, useSelector } from "react-redux"

const Inbox2 = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!!localStorage.getItem("smartway_auth")) {
        dispatch(
          notifySearch({
            Start: 0,
            Length: 10,
          })
        )
      }
    }
    return () => {}
  }, [])
  return <>Hi !!</>
}
export default Inbox2
