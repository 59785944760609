import React from "react"

import { Container, Layout, SEO } from "src/sws-ui"
import Inbox2 from "../sws-ui/inbox/inbox2"

const InboxPage = () => (
  <Layout>
    <SEO title="Inbox" />
    <Container gridTemplateRows="1">
      <div class="cards cards--mist row-1 col-full">
        <Inbox2 />
      </div>
    </Container>
  </Layout>
)

export default InboxPage
